import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import Dashboard from "../pages/dashboard/Dashboard";
import Articles from "../pages/landing/Articles";
import EditArticle from "../pages/landing/EditArticle";
import EditLandingPage from "../pages/landing/EditLandingPage"; // Create this component for landing pages
import Login from "../pages/auth/Login";
import ProtectedRoute from "./ProtectedRoute";
import UsersPage from "../pages/user/UsersPage";
import BannersPage from "../pages/content/banner/BannerPage";
import EditBannerPage from "../pages/content/banner/EditBannerPage";
import FeaturesPage from "../pages/content/feature/FeaturesPage";

const AdminRoutes: React.FC = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminLayout />
            </ProtectedRoute>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="users" element={<UsersPage />} />

          <Route path="articles/edit/:id" element={<EditArticle />} />

          {/* Landing Page Routes */}
          <Route
            path="landing/terms"
            element={<EditLandingPage pageType="terms" />}
          />
          <Route
            path="landing/privacy"
            element={<EditLandingPage pageType="privacy" />}
          />
          <Route
            path="landing/about"
            element={<EditLandingPage pageType="about" />}
          />
          <Route path="landing/articles" element={<Articles />} />
          <Route path="content/banners" element={<BannersPage />} />
          <Route path="content/features" element={<FeaturesPage />} />
          <Route path="content/banners/edit/:uuid" element={<EditBannerPage />} />

        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default AdminRoutes;
