import React, { useState } from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";

interface RoleUpdateDialogProps {
  open: boolean;
  roles: string[];
  currentRoles: string[];
  onClose: () => void;
  onSave: (updatedRoles: string[]) => void;
}

const RoleUpdateDialog: React.FC<RoleUpdateDialogProps> = ({
  open,
  roles,
  currentRoles,
  onClose,
  onSave,
}) => {
  const [selectedRoles, setSelectedRoles] = useState<string[]>(currentRoles);

  const handleRoleChange = (role: string) => {
    setSelectedRoles((prev) =>
      prev.includes(role)
        ? prev.filter((r) => r !== role) // Remove role
        : [...prev, role] // Add role
    );
  };

  const handleSave = () => {
    onSave(selectedRoles);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Update Roles</DialogTitle>
      <DialogContent>
        <FormGroup>
          {roles.map((role) => (
            <FormControlLabel
              key={role}
              control={
                <Checkbox
                  checked={selectedRoles.includes(role)}
                  onChange={() => handleRoleChange(role)}
                />
              }
              label={role}
            />
          ))}
        </FormGroup>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSave} variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RoleUpdateDialog;
