import React, { useEffect, useState } from "react";
import { Box, Typography, TextField, Button, Paper, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import Config from "../../../config";
import axios from "axios";

export interface Banner {
  uuid: string;
  type: string;
  is_active: boolean;
  content: Record<string, string>;
  is_current: boolean;
}

const EditBannerPage: React.FC = () => {
  const { uuid } = useParams<{ uuid: string }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [banner, setBanner] = useState<Banner | null>(location.state?.banner || null);
  const [content, setContent] = useState<Record<string, string>>(banner?.content || {});
  const [newLang, setNewLang] = useState<string>(""); // Input for new language code
  const [newMessage, setNewMessage] = useState<string>(""); // Input for new message

  useEffect(() => {
    const fetchBanner = async () => {
      if (!banner) {
        try {
          const response = await axios.get(
            `${Config.apiDomain}/api/admin/banners/${uuid}`,
            { withCredentials: true }
          );
          setBanner(response.data);
          setContent(response.data.content);
        } catch (error: any) {
          alert(error.response?.data?.detail || "Error fetching banner");
        }
      }
    };

    fetchBanner();
  }, [uuid, banner]);

  const handleContentChange = (lang: string, value: string) => {
    setContent((prevContent) => ({
      ...prevContent,
      [lang]: value,
    }));
  };

  const handleAddLanguage = () => {
    if (!newLang.trim() || !newMessage.trim()) {
      alert("Both language and message fields are required.");
      return;
    }
    if (content[newLang]) {
      alert(`Language "${newLang}" already exists.`);
      return;
    }

    setContent((prevContent) => ({
      ...prevContent,
      [newLang]: newMessage,
    }));

    setNewLang("");
    setNewMessage("");
  };

  const handleRemoveLanguage = (lang: string) => {
    setContent((prevContent) => {
      const newContent = { ...prevContent };
      delete newContent[lang];
      return newContent;
    });
  };

  const handleSave = async () => {
    try {
      // Transform content into the required format
      const transformedContent = Object.entries(content).reduce(
        (acc, [lang, message]) => {
          acc[lang] = { message };
          return acc;
        },
        {} as Record<string, { message: string }>
      );
  
      await axios.put(
        `${Config.apiDomain}/api/admin/banners/${uuid}`,
        { content: transformedContent },
        { withCredentials: true }
      );
      alert("Banner updated successfully!");
      navigate("/admin/content/banners");
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error saving banner");
    }
  };

  if (!banner) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Edit Banner: {banner.type}
      </Typography>
      <Paper sx={{ p: 3 }}>
        {/* Existing Languages */}
        {Object.entries(content).map(([lang, message]) => (
          <Box key={lang} mb={2} display="flex" alignItems="center" gap={2}>
            <Typography>{lang}</Typography>
            <TextField
              fullWidth
              value={message}
              onChange={(e) => handleContentChange(lang, e.target.value)}
            />
            <IconButton
              color="error"
              onClick={() => handleRemoveLanguage(lang)}
              aria-label={`Remove language ${lang}`}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        ))}

        {/* Add New Language */}
        <Box mt={3} mb={3}>
          <Typography variant="h6">Add New Language</Typography>
          <Box display="flex" gap={2} alignItems="center">
            <TextField
              label="Language Code"
              value={newLang}
              onChange={(e) => setNewLang(e.target.value)}
            />
            <TextField
              label="Message"
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              fullWidth
            />
            <Button variant="contained" onClick={handleAddLanguage}>
              Add
            </Button>
          </Box>
        </Box>

        {/* Save and Cancel Buttons */}
        <Box mt={3}>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Changes
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            sx={{ ml: 2 }}
            onClick={() => navigate("/admin/content/banners")}
          >
            Cancel
          </Button>
        </Box>
      </Paper>
    </Box>
  );
};

export default EditBannerPage;
