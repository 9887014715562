import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Chip,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import useUsers from "./hooks/useUsers";
import UserDetailsDialog from "./UserDetailsDialog";
import RoleUpdateDialog from "./RoleUpdateDialog";

const UsersPage: React.FC = () => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [page, setPage] = useState<number>(0);
  const limit = 30;
  const {
    users,
    roles,
    loading,
    total,
    fetchUsers,
    updateUserRoles,
    searchUsersByEmail,
  } = useUsers();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [roleDialogOpen, setRoleDialogOpen] = useState(false);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSubmit = () => {
    if (searchTerm.trim()) {
      searchUsersByEmail(searchTerm.trim());
    } else {
      fetchUsers(0, limit); // Fetch all users if search term is cleared
    }
  };

  useEffect(() => {
    fetchUsers(page, limit);
  }, [page]);

  const handleNextPage = () => {
    if ((page + 1) * limit < total) setPage(page + 1);
  };

  const handlePreviousPage = () => {
    if (page > 0) setPage(page - 1);
  };

  const handleOptionsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    user: any
  ) => {
    setAnchorEl(event.currentTarget);
    setSelectedUser(user);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleViewDetails = () => {
    setDialogOpen(true);
    setAnchorEl(null); // Close menu
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedUser(null);
  };

  const openRoleUpdateDialog = (user: any) => {
    setSelectedUser(user);
    setRoleDialogOpen(true);
  };

  const handleRoleUpdateSave = async (updatedRoles: string[]) => {
    if (!selectedUser) return;
    await updateUserRoles(selectedUser.uuid, updatedRoles);
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Users
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Total Users: {total}
      </Typography>

      <Box mb={2} display="flex" gap={2} alignItems="center">
        <TextField
          label="Search by Email"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          fullWidth
        />
        <Button variant="contained" onClick={handleSearchSubmit}>
          Search
        </Button>
      </Box>

      {loading ? (
        <CircularProgress />
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell>UUID</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Role</TableCell>
                <TableCell>Auth Type</TableCell>
                <TableCell align="right">Options</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.uuid}>
                  <TableCell>{user.uuid}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.username || "Not Finished SignUp"}
                  </TableCell>
                  <TableCell>
                    {user.roles.map((role: string, index: number) => (
                      <Chip
                        key={index}
                        label={role}
                        size="small"
                        sx={{ margin: "2px" }} // Add spacing between chips
                      />
                    ))}
                    <IconButton
                      size="small"
                      onClick={() => openRoleUpdateDialog(user)}
                      aria-label="update role"
                      sx={{ ml: 1 }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </IconButton>
                  </TableCell>
                  <TableCell>{user.auth_type}</TableCell>
                  <TableCell align="right">
                    <IconButton onClick={(e) => handleOptionsClick(e, user)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      anchorEl={anchorEl}
                      open={
                        Boolean(anchorEl) && selectedUser?.uuid === user.uuid
                      }
                      onClose={handleCloseMenu}
                    >
                      <MenuItem onClick={handleViewDetails}>
                        View Details
                      </MenuItem>
                    </Menu>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      <Box display="flex" justifyContent="space-between" mt={2}>
        <Button
          variant="contained"
          onClick={handlePreviousPage}
          disabled={page === 0}
        >
          Previous
        </Button>
        <Typography variant="body1">
          Page {page + 1} of {Math.ceil(total / limit)}
        </Typography>
        <Button
          variant="contained"
          onClick={handleNextPage}
          disabled={(page + 1) * limit >= total}
        >
          Next
        </Button>
      </Box>

      {/* User Details Dialog */}
      <UserDetailsDialog
        open={dialogOpen}
        onClose={handleCloseDialog}
        user={selectedUser}
      />

      {/* Role Update Dialog */}
      <RoleUpdateDialog
        open={roleDialogOpen}
        roles={roles} // All available roles
        currentRoles={selectedUser?.roles || []} // Current roles of the user
        onClose={() => setRoleDialogOpen(false)}
        onSave={handleRoleUpdateSave}
      />
    </Box>
  );
};

export default UsersPage;
