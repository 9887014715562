import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Switch,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
} from "@mui/material";
import Config from "../../../config";
import axios from "axios";
import { useNavigate } from "react-router-dom";


export interface Banner {
  uuid: string;
  type: string;
  is_active: boolean;
  content: Record<string, string>;
  is_current: boolean;
}  

const BannersPage: React.FC = () => {
  const [banners, setBanners] = useState<Banner[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchBanners = async () => {
      try {
        const response = await axios.get(
          `${Config.apiDomain}/api/admin/banners`, // Replace with your banners API endpoint
          {
            withCredentials: true, // If authentication is required
          }
        );
        setBanners(response.data.banners); // Assuming the response contains a "banners" array
      } catch (error: any) {
        alert(error.response?.data?.detail || "Error fetching banners");
      }
    };

    fetchBanners();
  }, []);

  const toggleBannerStatus = async (uuid: string) => {
    try {
      await axios.put(
        `${Config.apiDomain}/api/admin/banners/${uuid}/toggle`, // Replace with your toggle endpoint
        {},
        { withCredentials: true }
      );
      setBanners((prevBanners) =>
        prevBanners.map((banner) =>
          banner.uuid === uuid ? { ...banner, is_active: !banner.is_active } : banner
        )
      );
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error updating banner status");
    }
  };

  const setCurrentBanner = async (uuid: string) => {
    try {
      await axios.put(
        `${Config.apiDomain}/api/admin/banners/${uuid}/set_current`, // Replace with your set current endpoint
        {},
        { withCredentials: true }
      );
      window.location.reload();
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error setting current banner");
    }
  };

  const handleEdit = (banner: Banner) => {
    navigate(`/admin/content/banners/edit/${banner.uuid}`, { state: { banner } });
  };


  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Banners
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Active</TableCell>
              <TableCell>Is Current</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {banners.map((banner) => (
              <TableRow key={banner.uuid}>
                <TableCell>{banner.type}</TableCell>
                <TableCell>
                  <Switch
                    checked={banner.is_active}
                    onChange={() => toggleBannerStatus(banner.uuid)}
                  />
                </TableCell>
                <TableCell>
                  {banner.is_current ? (
                    <Chip label="Current" color="success" size="small" />
                  ) : (
                    <Chip label="Not Current" color="default" size="small" />
                  )}
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setCurrentBanner(banner.uuid)}
                  >
                    {banner.is_current ? "Remove Current" : "Set Current"}
                  </Button>{" "}
                  <Button
                    variant="contained"
                    onClick={() => handleEdit(banner)}
                  >
                    Edit
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default BannersPage;