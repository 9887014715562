import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from "@mui/material";
import Config from "../../../config";
import axios from "axios";

// Define the Feature Toggle Interface
export interface FeatureToggle {
  uuid: string;
  name: string;
  description: string;
  is_enabled: boolean;
  tags: string[];
  metadata: Record<string, string>;
}

const FeaturesPage: React.FC = () => {
  const [features, setFeatures] = useState<FeatureToggle[]>([]);

  // Fetch all feature toggles at the beginning
  useEffect(() => {
    const fetchFeatureToggles = async () => {
      try {
        const response = await axios.get(`${Config.apiDomain}/api/admin/feature/toggles`, {
          withCredentials: true,
        });
        setFeatures(response.data.toggles);
      } catch (error: any) {
        alert(error.response?.data?.detail || "Error fetching feature toggles");
      }
    };

    fetchFeatureToggles();
  }, []);

  const toggleFeatureStatus = async (uuid: string, isEnabled: boolean) => {
    try {
      await axios.put(
        `${Config.apiDomain}/api/admin/feature/${uuid}/toggle`,
        { is_enabled: !isEnabled },
        { withCredentials: true }
      );
      setFeatures((prevFeatures) =>
        prevFeatures.map((feature) =>
          feature.uuid === uuid ? { ...feature, is_enabled: !isEnabled } : feature
        )
      );
    } catch (error: any) {
      alert(error.response?.data?.detail || "Error toggling feature");
    }
  };

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Features
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Enabled</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {features.map((feature) => (
              <TableRow key={feature.uuid}>
                <TableCell>{feature.name}</TableCell>
                <TableCell>{feature.description}</TableCell>
                <TableCell>
                  <Switch
                    checked={feature.is_enabled}
                    onChange={() => toggleFeatureStatus(feature.uuid, feature.is_enabled)}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default FeaturesPage;
